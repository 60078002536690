import React, {useState, useCallback, useEffect} from 'react'
import { Pagination, Table, Button, Modal, Tooltip } from "antd";
import { Helmet } from "react-helmet";
import { renderMobileColumn } from "utils/renderMobileColumn";
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
	Field,
	formValueSelector,
	reduxForm,
} from "redux-form";
import { getMasterList, masterSelectors, ImportExcelFile, ExportExcelFile} from 'slices/masterSlice'
import useMasterLogic from 'customHooks/useMasterLogic';
import { ReduxFormFileField } from 'utils/ReduxFormFileField';
import { TfiReload } from "react-icons/tfi";
import StatusSegments from "components/StatusSegments";
import useSearchFilter from 'customHooks/useSearchFilter';
import { renderActions } from 'utils/renderActions';
import MainItemDetails from './MainItemDetails';

const masterName = "mainitem"
export const masterStatuses = [
	{ label: "Active", value: 1 },
	{ label: "Inactive", value: 0 },
];

const MainItem = () => {

  const { status } = useParams();
	const formSelector = formValueSelector(masterName);
	const dispatch = useDispatch()
	const loading = useSelector(masterSelectors?.getIsFetchingMasterList);
	const importing = useSelector(masterSelectors?.getIsImportingExcel);
	const exporting = useSelector(masterSelectors?.getIsExportingExcel);
	const list = useSelector(masterSelectors?.getMainItemsMasterList);
	// const { pageSize, totalElements, currentPage } = useSelector(
	// 	masterSelectors?.getMainItemPagination
	// );
  const { getColumnSearchProps, tableFilters } = useSearchFilter();

	// const getListData = useCallback(
	// 	(pageNum = 1, itemsPerPage = 10) => {
	// 		dispatch(
	// 			getMasterList({
	// 				masterName,
	// 				query: {
	// 					// page: pageNum,
	// 					// pageSize: itemsPerPage,
    //         is_active: status,
    //         ...tableFilters,
	// 				},
	// 			})
	// 		);
	// 	},
	// 	[status,tableFilters,dispatch]
	// );
    const getListData = () =>{
        dispatch(
            getMasterList({
                masterName,
                query: {
                    // page: pageNum,
                    // pageSize: itemsPerPage,
        is_active: status,
        ...tableFilters,
                },
            })
        )
    }

    useEffect(()=>{
        getListData()
    },[status, tableFilters, dispatch])

    console.log('status', status)


	// const { handlePageChange } = useMasterLogic(getListData, masterName);

  const [data, setData] = useState({})
	const [type, setType] = useState("update")

	const handleSetData = (value) =>{
		setType("update")
		let temp = {...value};
		// temp.hsnId = value?.hsn_code
		// temp.categoryId = value?.category_id?.[0]?.id
		setData(temp)
	}

	const handleDuplicate = (value)=>{
		setType("new")
		let temp = {...value};
		// temp.hsnId = value?.hsn_code
		// temp.categoryId = value?.category_id?.[0]?.id
		setData(value)
	}

	const handleImport = () =>{
		dispatch(ImportExcelFile({
			masterName:'mainitem/export/excel',
			name:'mainitem'
		}))
	}
	const [show, setShow] = useState(false)
	const excelFile = useSelector(state => formSelector(state, "item_excel"));
	const handleExport = () =>{
		setShow(false);
		const form = new FormData();
		form.append("file", excelFile);
		dispatch(
			ExportExcelFile({
				masterName:"mainitem/upload/import",
				formData: form,
				configType: "multipart/form-data",
			})
		);
	}


  const columns = [
    {
        title: "Main Item",
        render: data => {
            const primaryText = data?.name || "-";
            const secondaryText = `${data?.category_id?.[0]?.name || "-"}, ${
                data?.subcategory_id?.[0]?.name || "-"
            }`;
            const ternaryText = `${data?.season || "-"}, ${
                data?.collection || "-"
            }`;
            return renderMobileColumn(primaryText, secondaryText, ternaryText);
        },
        responsive: ["xs"],
        ...getColumnSearchProps("Search", "universal_search"),
    },
    {
        title: "Item Name",
        dataIndex: "name",
        key: "name",
        responsive: ["sm"],
        render: value => value || "-",
        onFilter: (value, record) => record.name.startsWith(value),
        sorter: (a, b) => a.name.localeCompare(b.name),
        filterSearch: true,
    },
    {
        title: "Category Name",
        dataIndex: "category_id",
        key: "category_id",
        responsive: ["sm"],
        render: value => value || "-",
        onFilter: (value, record) => record.category_id.startsWith(value),
        filterSearch: true,
    },
    {
        title: "Subcategory Name",
        dataIndex: "subcategory_id",
        key: "subcategory_id",
        responsive: ["sm"],
        render: value => value || "-",
        onFilter: (value, record) => record.subcategory_id.startsWith(value),
        sorter: (a, b) => a.subcategory_id.localeCompare(b.subcategory_id),
        filterSearch: true,
    },
    {
        title: "Season",
        dataIndex: "season",
        key: "season",
        responsive: ["sm"],
        render: value => value || "-",
        onFilter: (value, record) => record.season.startsWith(value),
        filterSearch: true,
    },
    {
        title: "Collection",
        dataIndex: "collection",
        key: "collection",
        responsive: ["sm"],
        render: value => value || "-",
        onFilter: (value, record) => record.collection.startsWith(value),
        filterSearch: true,
    },
    {
        title: "Upload Type",
        dataIndex: "type_of_item",
        key: "type_of_item",
        responsive: ["sm"],
        render: value => value || "-",
        onFilter: (value, record) => record.type_of_item.startsWith(value),
        filterSearch: true,
    },
    {
        title: "MRP",
        dataIndex: "mrp",
        key: "mrp",
        responsive: ["sm"],
        render: value => value || "-",
        onFilter: (value, record) =>
            String(record.mrp).startsWith(String(value)),
        sorter: (a, b) => a.mrp - b.mrp,
        filterSearch: true,
    },
    {
        title: "WSP",
        dataIndex: "wsp",
        key: "wsp",
        responsive: ["sm"],
        render: value => value || "-",
    },

    {
        title: "Actions",
        key: "Actions",
        dataIndex: "action",
        width: "20%",
        render: (_, data, index) => {
            const items = [
      {
                    label: "Duplicate",
                    onClick: () => handleDuplicate(data),
                }
            ];

            return renderActions(items);
        },
    },
]

  return (
    <>
    <Helmet>
	<title>Main Items</title>
	</Helmet>
    <div className='flex justify-between items-center mb-2'>
        <h1 className='text-2xl'>Main Item List</h1>
		<div className='flex gap-5'>
    <StatusSegments masterName={masterName+"s"} options={masterStatuses} />
			<Tooltip title="Reload">
		<Button
			type='default' size='small'
			loading={loading}
			disabled={loading}
			onClick={()=>getListData(1, 10)}
		>
			<TfiReload/>
		</Button>
		</Tooltip>
		
		<Button
			type='default' size='small'
			loading={exporting}
			disabled={exporting}
			onClick={()=>setShow(true)}
		>
			Import Excel
		</Button>
		<Button
			onClick={handleImport}
			loading={importing}
			disabled={exporting}
			type='default' size='small'
		>
			Export Excel
		</Button>
		<Link to="/mainitems/add" target='_blank'>
        <Button type='primary' size='small' className='w-[80px]'>Add New</Button>
		</Link>
		</div>
		
    </div>
    <Table
    dataSource={list}
    columns={columns}
    rowKey={item => item?.id}
    loading={loading}
    scroll={{y:440}}
    bordered
	style={{minHeight:"400px"}}
    pagination={{
        pageSize: 100,
        showSizeChanger:false
    }}
    />
	{/* {list?.length ? (
		<div className='flex justify-end'>
		<Pagination
			pageSize={pageSize}
			total={totalElements}
			current={currentPage}
			onChange={handlePageChange}
		/>
		</div>
	) : null} */}
	<div className='mb-5'></div>
    {/* <MainItemDetails/> */}
	<Modal
		title="Upload Excel File"
		open={show}
		onOk={handleExport}
		onCancel={()=>setShow(false)}
	>
		<Field
			name="item_excel"
			component={ReduxFormFileField}
			className="mt-1"
			placeholder="Item Image"
			itemname="excel file"
		/>
		<p className="mt-2 text-lg font-bold">{excelFile?.name}</p>
	</Modal>
    </>
  )
}

export default reduxForm({
	form: masterName,
	enableReinitialize: true,
})(MainItem);
