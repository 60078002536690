import React from 'react'
import { Checkbox } from 'antd'

const PdfRoleForm = ({pdfRole, setPdfRole}) => {
  return (
    <>
    <p className='font-bold my-4'>Pdf Roles :</p>
    <div className='flex gap-5 flex-wrap'>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={pdfRole.pdfGenerator} onChange={(e)=>setPdfRole({...pdfRole, pdfGenerator: e.target.checked})}/>
            <label>PDF Generate</label>
        </div>
    </div>
    </>
  )
}

export default PdfRoleForm