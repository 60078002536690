import { Checkbox } from 'antd'
import React from 'react'

const ReportRoleForm = ({reportRole, setReportRole}) => {

  return (
    <>
    <p className='font-bold my-4'>Report Roles :</p>
    <div className='flex gap-5 flex-wrap'>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={reportRole.imageUploadReport} onChange={(e)=>setReportRole({...reportRole, imageUploadReport: e.target.checked})}/>
            <label>Image Upload Report</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={reportRole.fabricReport} onChange={(e)=>setReportRole({...reportRole, fabricReport: e.target.checked})}/>
            <label>Fabric Report</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={reportRole.chalanReport} onChange={(e)=>setReportRole({...reportRole, chalanReport: e.target.checked})}/>
            <label>Challan Report</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={reportRole.openingStockReport} onChange={(e)=>setReportRole({...reportRole, openingStockReport: e.target.checked})}/>
            <label>Opening Stock Report</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={reportRole.katkutReport} onChange={(e)=>setReportRole({...reportRole, katkutReport: e.target.checked})}/>
            <label>katkutReport</label>
        </div>
     
    </div>
    </>
  )
}

export default ReportRoleForm