import React from 'react'
import { Checkbox } from 'antd'

const WorkingForm = ({workingRole, setWorkingRole}) => {

  return (
    <>
    <p className='font-bold my-4'>Working Roles :</p>
    <div className='flex gap-5 flex-wrap'>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={workingRole.inwardStockEntry} onChange={(e)=>setWorkingRole({...workingRole, inwardStockEntry: e.target.checked})}/>
            <label>Inward Stock</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={workingRole.salesOrderReport} onChange={(e)=>setWorkingRole({...workingRole, salesOrderReport: e.target.checked})}/>
            <label>Sale Order</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={workingRole.closingStockReport} onChange={(e)=>setWorkingRole({...workingRole, closingStockReport: e.target.checked})}/>
            <label>Closing Stock</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={workingRole.outwardStockEntry} onChange={(e)=>setWorkingRole({...workingRole, outwardStockEntry: e.target.checked})}/>
            <label>Outward Stock</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={workingRole.ledger} onChange={(e)=>setWorkingRole({...workingRole, ledger: e.target.checked})}/>
            <label>Ledger</label>
        </div>
    </div>
    </>
  )
}

export default WorkingForm