import React from 'react'
import { Checkbox } from 'antd'

const MasterRoleForm = ({masterRole, setMasterRole}) => {

  return (
    <>
    <p className='font-bold my-4'>Master Roles :</p>
    <div className='flex gap-5 flex-wrap'>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={masterRole.categories} onChange={(e)=>setMasterRole({...masterRole, categories: e.target.checked})}/>
            <label>Category</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={masterRole.subCategories} onChange={(e)=>setMasterRole({...masterRole, subCategories: e.target.checked})}/>
            <label>Sub Category</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={masterRole.seasons} onChange={(e)=>setMasterRole({...masterRole, seasons: e.target.checked})}/>
            <label>Season</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={masterRole.collections} onChange={(e)=>setMasterRole({...masterRole, collections: e.target.checked})}/>
            <label>Collection</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={masterRole.mainItems} onChange={(e)=>setMasterRole({...masterRole, mainItems: e.target.checked})}/>
            <label>Main Item</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={masterRole.charges} onChange={(e)=>setMasterRole({...masterRole, charges: e.target.checked})}/>
            <label>Charges</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={masterRole.hsnCode} onChange={(e)=>setMasterRole({...masterRole, hsnCode: e.target.checked})}/>
            <label>HSN Code</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={masterRole.cities} onChange={(e)=>setMasterRole({...masterRole, cities: e.target.checked})}/>
            <label>City</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={masterRole.customerGroup} onChange={(e)=>setMasterRole({...masterRole, customerGroup: e.target.checked})}/>
            <label>Customer Group</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={masterRole.agent} onChange={(e)=>setMasterRole({...masterRole, agent: e.target.checked})}/>
            <label>Agent</label>
        </div>
        <div className='mb-3'>
            <Checkbox className="mr-2"
            value={masterRole.transport} onChange={(e)=>setMasterRole({...masterRole, transport: e.target.checked})}/>
            <label>transport</label>
        </div>
     
    </div>
    </>
  )
}

export default MasterRoleForm