import React, {useState} from 'react'
import { Button } from 'antd'
import { useDispatch, useSelector } from "react-redux";
import { AddUserRole, masterSelectors } from 'slices/masterSlice';
import { Helmet } from 'react-helmet';
import MasterRoleForm from './forms/MasterRoleForm';
import ReportRoleForm from './forms/ReportRoleForm';
import WorkingForm from './forms/WorkingForm';
import PdfRoleForm from './forms/PdfRoleForm';

const masterName = "pageacess"
const UserRoles = () => {

  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getAddingUserRoleLoading);

    const [roleName, setRoleName] = useState('')

    const [masterRole, setMasterRole] = useState({
      categories: false,
      subCategories: false,
      seasons: false,
      collections: false, 
      mainItems: false,
      charges: false, 
      hsnCode: false,
      cities: false, 
      customerGroup: false,
      agent: false, 
      transport: false
    })

    const [reportRole, setReportRole] = useState({
      imageUploadReport: false,
      fabricReport: false,
      chalanReport: false,
      openingStockReport: false, 
      katkutReport: false
    })

    const [pdfRole, setPdfRole] = useState({
      pdfGenerator: false,
    })

    const [workingRole, setWorkingRole] = useState({
      inwardStockEntry: false,
      salesOrderReport: false,
      closingStockReport: false,
      outwardStockEntry: false, 
      ledger: false
    })

    const [error, setError] = useState("")
    const handleSubmit = async() =>{
      if(roleName === ""){
        return setError("Role Name is Required")
      }else{
        setError("")
      }
      let temp = {...masterRole, ...reportRole, ...pdfRole, ...workingRole}
      let postData = {}
      for (const key in temp) {
        if (temp.hasOwnProperty(key)) {
          postData[key] = temp[key] ? "1" : "0";
        }
      }
      postData.role = roleName;

      let res = await dispatch(AddUserRole({masterName, formData:postData}))
      if(res?.type === "master/addUserRole/fulfilled"){
        setRoleName('')

    setMasterRole({
      categories: false,
      subCategories: false,
      seasons: false,
      collections: false, 
      mainItems: false,
      charges: false, 
      hsnCode: false,
      cities: false, 
      customerGroup: false,
      agent: false, 
      transport: false
    })

    setReportRole({
      imageUploadReport: false,
      fabricReport: false,
      chalanReport: false,
      openingStockReport: false, 
      katkutReport: false
    })

    setPdfRole({
      pdfGenerator: false,
    })

    setWorkingRole({
      inwardStockEntry: false,
      salesOrderReport: false,
      closingStockReport: false,
      outwardStockEntry: false, 
      ledger: false
    })
      }

    }

  return (
    <>
    <Helmet>
	  <title>User Roles</title>
	  </Helmet>
    <div className='flex justify-between items-center'>
    <h1 className='text-xl'>Add New User Role</h1>
    <Button type='primary' className='bg-primaryBase'
    disabled={loading}
    loading={loading}
    onClick={handleSubmit}
    >Add</Button>
    </div>
    <div className='mt-4'>
    <label>Role Name</label><br></br>
    <input type='text' value={roleName} onChange={(e)=>setRoleName(e.target.value)} className='c-input border'/>
    {error ? <p className='text-redBase'>{error}</p> : null}
    </div>
    <MasterRoleForm masterRole={masterRole} setMasterRole={setMasterRole}/>
    <ReportRoleForm reportRole={reportRole} setReportRole={setReportRole}/>
    <PdfRoleForm pdfRole={pdfRole} setPdfRole={setPdfRole}/>
    <WorkingForm workingRole={workingRole} setWorkingRole={setWorkingRole}/>
    </>
  )
}

export default UserRoles