import { Button, Dropdown } from "antd";
import { useState } from "react";
import {
	MdArrowDropDown,
	MdClose,
	MdMenu,
	MdOutlineLogout,
	MdSync,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
	allMasterSync,
	chalanSync,
	fabricSync,
	lotSetSync,
	masterSelectors,
} from "slices/masterSlice";
import { userLogout, userSelectors } from "slices/userSlice";
import AuthorizedRoutes from "./AuthorizedRoutes";

const masterList = [
	{ name: "Categories", link: "category/1" },
	{ name: "Sub-categories", link: "subcategory/1" },
	{ name: "Seasons", link: "season" },
	{ name: "Collections", link: "collection" },
	{ name: "Main-items", link: "mainitem/1" },
];

const reportsList = [
	{ name: "Image Upload Report", link: "reports/image-upload" },
	{ name: "Fabric Report", link: "reports/fabric" },
	{ name: "Chalan Report", link: "reports/chalan" },
	{ name: "Opening Stock Report", link: "reports/ready-stock" },
	{ name: "Katkut Report", link: "reports/katkut" },
];

const links = [{ name: "PDF Generator", link: "/pdf" }];

const workingList = [
	{ name: "Inward Stock Entry", link: "working/readyStockEntry" },
	{ name: "Sales Order Report", link: "working/sales-order" },
	{
		name: "Closing Stock Report",
		link: "working/readyStockWorkingReport",
	},
	// { name: "Outward", link: "working/outward" },
	{ name: "Outward Stock Entry", link: "working/outward/outwardStockEntry" },
];

const Navbar = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);

	const { isLoggedIn, token } = useSelector(userSelectors?.getUserDetails);
	const isMasterSyncing = useSelector(masterSelectors?.getIsSyncingMasters);
	const isChalanSyncing = useSelector(masterSelectors?.getIsSyncingChalan);
	const isFabricSyncing = useSelector(masterSelectors?.getIsSyncingFabric);
	const isSetSyncing = useSelector(masterSelectors?.getIsSyncingSet);

	const user = useSelector(userSelectors?.getUserDetails);

	// const masterListItems = useMemo(
	// 	() => [
	// 		...masterList?.map((master, index) => {
	// 			return {
	// 				label: (
	// 					<>
	// 						<Link
	// 							to={`/${master?.link}`}
	// 							onClick={() => setOpen(false)}
	// 							type="link"
	// 							target="_blank"
	// 							className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
	// 						>
	// 							{master?.name}
	// 						</Link>
	// 						{masterList?.length - 1 !== index && <hr className="mt-1 mb-1" />}
	// 					</>
	// 				),
	// 				value: master?.name,
	// 				key: index,
	// 			};
	// 		}),
	// 	],
	// 	[]
	// );

	// const reportListItems = useMemo(
	// 	() => [
	// 		...reportsList?.map((master, index) => {
	// 			return {
	// 				label: (
	// 					<>
	// 						<Link
	// 							to={`/${master?.link}`}
	// 							onClick={() => setOpen(false)}
	// 							type="link"
	// 							target="_blank"
	// 							className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
	// 						>
	// 							{master?.name}
	// 						</Link>
	// 						{reportsList?.length - 1 !== index && (
	// 							<hr className="mt-1 mb-1" />
	// 						)}
	// 					</>
	// 				),
	// 				value: master?.name,
	// 				key: index,
	// 			};
	// 		}),
	// 	],
	// 	[]
	// );

	const masterListItems1 = [
		user?.accessRights?.categories === "1"
			? {
					label: (
						<>
							<Link
								to={`/category/1`}
								onClick={() => setOpen(false)}
								type="link"
								target="_blank"
								className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
							>
								Categories
							</Link>
							<hr className="mt-1 mb-1" />
						</>
					),
			  }
			: null,

		user?.accessRights?.subcategories === "1"
			? {
					label: (
						<>
							{" "}
							<Link
								to={`/subcategory/1`}
								onClick={() => setOpen(false)}
								type="link"
								target="_blank"
								className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
							>
								Sub-categories
							</Link>
							<hr className="mt-1 mb-1" />
						</>
					),
			  }
			: null,
		user?.accessRights?.seasons === "1"
			? {
					label: (
						<>
							{" "}
							<Link
								to={`/season`}
								onClick={() => setOpen(false)}
								type="link"
								target="_blank"
								className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
							>
								Seasons
							</Link>
							<hr className="mt-1 mb-1" />
						</>
					),
			  }
			: null,

		user?.accessRights?.collections === "1"
			? {
					label: (
						<>
							{" "}
							<Link
								to={`/collection
						`}
								onClick={() => setOpen(false)}
								type="link"
								target="_blank"
								className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
							>
								Collections
							</Link>
							<hr className="mt-1 mb-1" />
						</>
					),
			  }
			: null,
		user?.accessRights?.mainItems === "1"
			? {
					label: (
						<>
							{" "}
							<Link
								to={`/mainitems/1
						`}
								onClick={() => setOpen(false)}
								type="link"
								target="_blank"
								className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
							>
								Main-items
							</Link>
							<hr className="mt-1 mb-1" />
						</>
					),
			  }
			: null,
			user?.accessRights?.mainItems === "1"
			? {
					label: (
						<>
							{" "}
							<Link
								to={`/mainitem/1
						`}
								onClick={() => setOpen(false)}
								type="link"
								target="_blank"
								className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
							>
								Main-items (Old)
							</Link>
							<hr className="mt-1 mb-1" />
						</>
					),
			  }
			: null,

		{label:(<>
				<Link
					to={`/charges`}
					onClick={() => setOpen(false)}
					type="link"
					target="_blank"
					className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
				>
					Charges
				</Link>
				<hr className="mt-1 mb-1" />
			</>
		)},
		{label:(<>
				<Link
					to={`/hsn`}
					onClick={() => setOpen(false)}
					type="link"
					target="_blank"
					className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
				>
					HSN Code
				</Link>
				<hr className="mt-1 mb-1" />
			</>
		)},
		{label:(<>
			<Link
				to={`/city`}
				onClick={() => setOpen(false)}
				type="link"
				target="_blank"
				className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
			>
				Cities
			</Link>
			<hr className="mt-1 mb-1" />
		</>
	)},
	{label:(<>
		<Link
			to={`/customer-group`}
			onClick={() => setOpen(false)}
			type="link"
			target="_blank"
			className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
		>
			Customer Group
		</Link>
		<hr className="mt-1 mb-1" />
	</>
)},
{label:(<>
	<Link
		to={`/agent`}
		onClick={() => setOpen(false)}
		type="link"
		target="_blank"
		className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
	>
		Agent
	</Link>
	<hr className="mt-1 mb-1" />
</>
)},
{label:(<>
	<Link
		to={`/transport`}
		onClick={() => setOpen(false)}
		type="link"
		target="_blank"
		className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
	>
		Transport
	</Link>
	<hr className="mt-1 mb-1" />
</>
)},
{label:(<>
	<Link
		to={`/customer`}
		onClick={() => setOpen(false)}
		type="link"
		target="_blank"
		className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
	>
		Customer
	</Link>
	{/* <hr className="mt-1 mb-1" /> */}
</>
)},
	];

	const reportListItems1 = [
		user?.accessRights?.imageUploadReport === "1"
			? {
					label: (
						<>
							<Link
								to={`/reports/image-upload`}
								onClick={() => setOpen(false)}
								type="link"
								target="_blank"
								className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
							>
								Image Upload Report
							</Link>
							<hr className="mt-1 mb-1" />
						</>
					),
			  }
			: null,

		user?.accessRights?.fabricReport === "1"
			? {
					label: (
						<>
							{" "}
							<Link
								to={`/reports/fabric`}
								onClick={() => setOpen(false)}
								type="link"
								target="_blank"
								className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
							>
								Fabric Report
							</Link>
							<hr className="mt-1 mb-1" />
						</>
					),
			  }
			: null,
		user?.accessRights?.chalanReport === "1"
			? {
					label: (
						<>
							{" "}
							<Link
								to={`/reports/chalan`}
								onClick={() => setOpen(false)}
								type="link"
								target="_blank"
								className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
							>
								Chalan Report
							</Link>
							<hr className="mt-1 mb-1" />
						</>
					),
			  }
			: null,

		user?.accessRights?.readyStockReport === "1"
			? {
					label: (
						<>
							{" "}
							<Link
								to={`/reports/ready-stock
						`}
								onClick={() => setOpen(false)}
								type="link"
								target="_blank"
								className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
							>
								Opening Stock Report
							</Link>
							<hr className="mt-1 mb-1" />
						</>
					),
			  }
			: null,
		user?.accessRights?.katkutReport === "1"
			? {
					label: (
						<>
							{" "}
							<Link
								to={`/reports/katkut
						`}
								onClick={() => setOpen(false)}
								type="link"
								target="_blank"
								className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
							>
								Katkut Report
							</Link>
							{/* <hr className="mt-1 mb-1" /> */}
						</>
					),
			  }
			: null,
	];

	// const workingListItems = useMemo(
	// 	() => [
	// 		...workingList?.map((master, index) => {
	// 			return {
	// 				label: (
	// 					<>
	// 						<Link
	// 							to={`/${master?.link}`}
	// 							onClick={() => setOpen(false)}
	// 							type="link"
	// 							target="_blank"
	// 							className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
	// 						>
	// 							{master?.name}
	// 						</Link>
	// 						{workingList?.length - 1 !== index && (
	// 							<hr className="mt-1 mb-1" />
	// 						)}
	// 					</>
	// 				),
	// 				value: master?.name,
	// 				key: index,
	// 			};
	// 		}),
	// 	],
	// 	[]
	// );
	const settingListItems = [
		{label:<>
		<Link
			to={`/user-roles`}
			onClick={() => setOpen(false)}
			type="link"
			target="_blank"
			className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
		>
			User Roles
		</Link>
		<hr className="mt-1 mb-1" />
		</>
		},
		{label:<>
			<Link
				to={`/role-list`}
				onClick={() => setOpen(false)}
				type="link"
				target="_blank"
				className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
			>
				Role Permission
			</Link>
			{/* <hr className="mt-1 mb-1" /> */}
			</>
			}
	]

	const workingListItems1 = [
		user?.accessRights?.inwardStockEntry === "1"
			? {
					label:
						user?.accessRights?.inwardStockEntry === "1" ? (
							<>
								<Link
									to={`/working/readyStockEntry`}
									onClick={() => setOpen(false)}
									type="link"
									target="_blank"
									className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
								>
									Inward Stock Entry
								</Link>
								<hr className="mt-1 mb-1" />
							</>
						) : null,
			  }
			: null,

		user?.accessRights?.salesOrderReport === "1"
			? {
					label: (
						<>
							{" "}
							<Link
								to={`/working/sales-order`}
								onClick={() => setOpen(false)}
								type="link"
								target="_blank"
								className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
							>
								Sales Order Report
							</Link>
							<hr className="mt-1 mb-1" />
						</>
					),
			  }
			: null,
		user?.accessRights?.inwardStockEntry === "1"
			? {
					label: (
						<>
							{" "}
							<Link
								to={`working/readyStockWorkingReport`}
								onClick={() => setOpen(false)}
								type="link"
								target="_blank"
								className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
							>
								Closing Stock Report
							</Link>
							<hr className="mt-1 mb-1" />
						</>
					),
			  }
			: null,

		user?.accessRights?.outwardStockEntry === "1"
			? {
					label: (
						<>
							{" "}
							<Link
								to={`/working/outward/outwardStockEntry 
						`}
								onClick={() => setOpen(false)}
								type="link"
								target="_blank"
								className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
							>
								Outward Stock Entry
							</Link>
							<hr className="mt-1 mb-1" />
						</>
					),
			  }
			: null,

		{
			label: (
				<>
					{" "}
					<Link
						to={`/working/ledgerReport
						`}
						onClick={() => setOpen(false)}
						type="link"
						target="_blank"
						className="text-md p-0 w-full text-left text-black hover:!text-primaryDark font-semibold"
					>
						Ledger Report
					</Link>
					{/* <hr className="mt-1 mb-1" /> */}
				</>
			),
		},
	];

	if (!(isLoggedIn && token)) {
		return <Navigate to="/login" />;
	}

	return (
		<>
			<div className="shadow-md w-full fixed top-0 left-0 z-10">
				<div className="flex items-center justify-between bg-white md:py-2 py-4 md:px-7 px-4">
					<div className="text-2xl cursor-pointer flex items-center text-secondaryDarl">
						<button
							className="flex items-center"
							type="button"
							onClick={() => navigate("/dashboard")}
						>
							<img
								src="/images/ethos.svg"
								alt="Ethos"
								className="px-3 h-9 w-full bg-white"
							/>
						</button>
						<ul
							className={`md:flex md:items-center md:pb-0 pb-6 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 md:ml-8 md:shadow-none shadow-2xl ${
								open
									? "top-16 ease-in duration-300"
									: "top-[-490px] ease-out duration-1000"
							}`}
						>
							<Dropdown
								menu={{ items: masterListItems1 }}
								placement="bottom"
								trigger="click"
							>
								<Button
									className="md:ml-4 text-xl md:my-0 my-3 flex items-center md:px-2 p-0 !text-black hover:!text-primaryBase font-semibold"
									type="link"
								>
									Masters
									<MdArrowDropDown />
								</Button>
							</Dropdown>
							<Dropdown
								menu={{ items: reportListItems1 }}
								placement="bottom"
								trigger="click"
							>
								<Button
									className="md:ml-4 text-xl md:my-0 my-3 flex items-center md:px-2 p-0 !text-black hover:!text-primaryBase font-semibold"
									type="link"
								>
									Reports
									<MdArrowDropDown />
								</Button>
							</Dropdown>
							{user?.accessRights?.pdfGenerator === "1"
								? links.map(link => (
										<li
											key={link?.name}
											className="md:ml-4 text-xl md:my-1 my-3 list-none"
										>
											<Link
												to={link?.link}
												onClick={() => setOpen(false)}
												className="!text-black hover:!text-primaryBase p-0 font-semibold no-underline"
												type="link"
												target="_blank"
											>
												{link?.name}
											</Link>
										</li>
								  ))
								: null}
							<Dropdown
								menu={{ items: workingListItems1 }}
								placement="bottom"
								trigger="click"
							>
								<Button
									className="md:ml-4 text-xl md:my-0 my-3 flex items-center md:px-2 p-0 !text-black hover:!text-primaryBase font-semibold"
									type="link"
								>
									Working
									<MdArrowDropDown />
								</Button>
							</Dropdown>

							<Dropdown
								menu={{ items: settingListItems }}
								placement="bottom"
								trigger="click"
							>
								<Button
									className="md:ml-4 text-xl md:my-0 my-3 flex items-center md:px-2 p-0 !text-black hover:!text-primaryBase font-semibold"
									type="link"
								>
									Settings
									<MdArrowDropDown />
								</Button>
							</Dropdown>

							<Button
								className="flex items-center text-secondaryDark p-0 my-3 md:invisible font-semibold"
								onClick={() => {
									setOpen(false);
									dispatch(lotSetSync());
								}}
								type="link"
								loading={isSetSyncing}
							>
								<p className="md:invisible visible md:w-0 mr-2 md:mr-0">
									Sync Set
								</p>
								<MdSync className="text-xl text-secondaryDark mt-0.5" />
							</Button>
							<Button
								className="flex items-center text-secondaryDark p-0 my-3 md:invisible font-semibold"
								onClick={() => {
									setOpen(false);
									dispatch(fabricSync());
								}}
								type="link"
								loading={isFabricSyncing}
							>
								<p className="md:invisible visible md:w-0 mr-2 md:mr-0">
									Sync Fabric
								</p>
								<MdSync className="text-xl text-secondaryDark mt-0.5" />
							</Button>
							<Button
								className="flex items-center text-secondaryDark p-0 my-3 md:invisible font-semibold"
								onClick={() => {
									setOpen(false);
									dispatch(chalanSync());
								}}
								type="link"
								loading={isChalanSyncing}
							>
								<p className="md:invisible visible md:w-0 mr-2 md:mr-0">
									Sync Chalan
								</p>
								<MdSync className="text-xl text-secondaryDark mt-0.5" />
							</Button>
							{/* <Button
								className="flex items-center text-secondaryDark p-0 my-3 md:invisible font-semibold"
								onClick={() => {
									setOpen(false);
									dispatch(readyStockSync());
								}}
								type="link"
								loading={isReadySyncing}
							>
								<p className="md:invisible visible md:w-0 mr-2 md:mr-0">
									Sync Ready-Stock
								</p>
								<MdSync className="text-xl text-secondaryDark mt-0.5" />
							</Button> */}
							<Button
								className="flex items-center text-secondaryDark p-0 my-3 md:invisible font-semibold"
								onClick={() => {
									setOpen(false);
									dispatch(allMasterSync());
								}}
								type="link"
								loading={isMasterSyncing}
							>
								<p className="md:invisible visible md:w-0 mr-2 md:mr-0">
									Sync Masters
								</p>
								<MdSync className="text-xl text-secondaryDark mt-0.5" />
							</Button>
							<Button
								className="flex items-center text-secondaryDark p-0 my-3 md:invisible font-semibold"
								onClick={() => dispatch(userLogout())}
								type="link"
							>
								<p className="md:invisible visible md:w-0 mr-2 md:mr-0">
									Logout
								</p>
								<MdOutlineLogout className="text-xl text-secondaryDark p-0" />
							</Button>
						</ul>
					</div>

					<button
						type="button"
						onClick={() => setOpen(!open)}
						className="md:hidden"
					>
						{open ? (
							<MdClose className="text-2xl" />
						) : (
							<MdMenu className="text-2xl" />
						)}
					</button>
					<div className="hidden md:flex ">
						<Button
							className="flex items-center bg-transparent hover:bg-primaryDark text-primaryBase hover:!text-white font-semibold px-2 py-1 mr-10 border border-primaryBase hover:border-transparent rounded"
							onClick={() => dispatch(lotSetSync())}
							type="button"
							icon={<MdSync className="text-xl mt-0.5" />}
							loading={isSetSyncing}
						>
							Sync Set
						</Button>
						<Button
							className="flex items-center bg-transparent hover:bg-primaryDark text-primaryBase hover:!text-white font-semibold px-2 py-1 mr-10 border border-primaryBase hover:border-transparent rounded"
							onClick={() => dispatch(fabricSync())}
							type="button"
							icon={<MdSync className="text-xl mt-0.5" />}
							loading={isFabricSyncing}
						>
							Sync Fabric
						</Button>
						<Button
							className="flex items-center bg-transparent hover:bg-primaryDark text-primaryBase hover:!text-white font-semibold px-2 py-1 mr-10 border border-primaryBase hover:border-transparent rounded"
							onClick={() => dispatch(chalanSync())}
							type="button"
							icon={<MdSync className="text-xl mt-0.5" />}
							loading={isChalanSyncing}
						>
							Sync Chalan
						</Button>
						{/* <Button
							className="flex items-center bg-transparent hover:bg-primaryDark text-primaryBase hover:!text-white font-semibold px-2 py-1 mr-10 border border-primaryBase hover:border-transparent rounded"
							onClick={() => dispatch(readyStockSync())}
							type="button"
							icon={<MdSync className="text-xl mt-0.5" />}
							loading={isReadySyncing}
						>
							Sync Ready-Stock
						</Button> */}
						<Button
							className="flex items-center bg-transparent hover:bg-primaryDark text-primaryBase hover:!text-white font-semibold px-2 py-1 mr-10 border border-primaryBase hover:border-transparent rounded"
							onClick={() => dispatch(allMasterSync())}
							type="button"
							icon={<MdSync className="text-xl mt-0.5" />}
							loading={isMasterSyncing}
						>
							Sync Masters
						</Button>
						<Button
							className="items-center text-black p-0"
							onClick={() => dispatch(userLogout())}
							type="link"
						>
							<MdOutlineLogout
								className="text-2xl text-black hover:text-secondaryDark p-0"
								style={{ color: "inherit" }}
							/>
						</Button>
					</div>
				</div>
			</div>
			<div className="mt-20">
				<div className={`relative p-5 ${open && "z-[-2]"}`}>
					<AuthorizedRoutes />
				</div>
			</div>
		</>
	);
};

export default Navbar;
